<template>
    <div class="flex w-full h-full items-center justify-center">
        <a :href="link" class="w-full h-full">
            <object v-if="file" class="w-full h-full" style="max-height:100%" :data="'https://tempfiles.cmsdesk.com/' + getAdvertFileUrl()" type="image/png">
                <img :src="getAdvertFileUrl()" >
            </object>
        </a>
    </div>
</template>

<script>
import DashboardWidgetTemplate from "@/v3/components/dashboardWigets/DashboardWidgetTemplate.vue";

export default {
    components: {
        DashboardWidgetTemplate
    },
    props:{
        size:{
            type: String,
            default: 'small'
        },
        file: {
            type: String,
            default: null
        },
        link:{
            type: String,
            default: null
        }
    },
    methods:{
        getAdvertFileUrl(){
            if(this.file){
                return '/storage/adverts/' + this.file;
            }
        }
    }
}
</script>
