<template>
    <div class="my-2">
      <collapsable-header v-if="resources && resources.count() >0" :collapsable="true"
                          :open="open" @toggleOpen="open = !open">
        <template v-slot:title>{{ title }}</template>
      </collapsable-header>
      <div v-if="resources && resources.count() >0" class="h-96"
           :class="{'hidden': !open || !resources,'h-full': resources.count() <=14}"
           style="max-height: 42rem;">

        <LazyList v-if="resources" :key="query"
                  :data="resources.toArray()"
                  :itemsPerRender="15"
        >
          <template v-slot="{item}">
            <div class="flex cursor-pointer hover:underline" @click="navigateToItem(item)">
              {{item.entity.properties.get(propertyKey)}}
            </div>
          </template>
        </LazyList>
      </div>
    </div>
</template>
<script>
import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue"
import LazyList from "lazy-load-list/vue"

export default {
  name: 'SearchElement',
  components: {CollapsableHeader, LazyList},
  props: {
    open: {},
    resources: {type: Object, default: null},
    query: {},
      title: {
        type: String,
          default: null
      },
      propertyKey:{
        type: String,
          default: null
      }
  },
    methods:{
        navigateToItem(item){
            let selfLink = item.entity.links.filter(link => link.rels.contains('self')).first().href;
            if(selfLink.includes('/api')) {
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            }else{
                this.$router.push(selfLink);
            }
        }
    }
}
</script>
<style scoped>
@media (max-height: 1000px) {
}
</style>
